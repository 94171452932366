import { createApp } from 'vue'
import 'element-plus/lib/theme-chalk/index.css'; //适用vue3
import App from './App.vue'
import Vue from "vue";
// import moment from 'moment'
import router from './router'
import store from './store'
import axios from 'axios'
// import {store} from '.store/index.js'///add
import qs from 'qs'
import 'babel-polyfill'
// import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css'; //适用vue2
import ElementPlus from 'element-plus';
import 'lib-flexible/flexible'

import 'dayjs/locale/zh-cn';
import locale from 'element-plus/lib/locale/lang/zh-cn'; // 分页组件显示英文解决方法




// import initRichText from '@/commom/initHTMLEditor.js'
// initRichText();
// import 'font-awesome/css/font-awesome.min.css'

//设置post请求头类型
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';
// axios.defaults.baseURL = "https://api.www.ddpta.com.cn/lydd/"
axios.defaults.baseURL = "https://api.ddzl.ddpta.com.cn/lydd/"
// axios.defaults.baseURL = "http://localhost:9090/lydd/"

const app = createApp(App)
app.use(ElementPlus, { locale }).use(store).use(router).mount('#app')


//vue3添加原型的方法
app.config.globalProperties.$axios = axios
app.config.globalProperties.$qs = qs
// Vue.prototype.$moment = moment;


// app.use(BaiduMap, {
//   // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
//   ak: "V2KAmRBSM25mqb7KyqxY73BQxFTxiPZ0"
// })

axios.defaults.contentType = 'application/json'
axios.defaults.withCredentials = true